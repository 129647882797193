import React from "react";

// Chakra imports
import { Flex, useColorModeValue } from "@chakra-ui/react";

// Custom components
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {

  return (
    <Flex align='center' direction='column'>
      <h3 style={{ fontSize: "30px", fontWeight: "bold", padding: "2px"}}>We Want More</h3>
      <HSeparator mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
