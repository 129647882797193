
import { Box, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import React, { useState, useEffect, useRef } from "react";
import { TableContainer, Table, Thead, Tbody, Tr, Th, Td, Button, Stack, useDisclosure } from '@chakra-ui/react';
import { AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, AlertDialogCloseButton } from '@chakra-ui/react'
import Card from "components/card/Card";
import { ToastContainer, toast } from 'react-toastify';


//service API and component
import { professinalList, professionalApproveAndReject } from "service/axiosClient";
import Loader from "components/loader/loader";
import Profile from "views/admin/profile/index"


export default function ProfessionalApproval() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef();
    const [dialogMessage, setDialogMessage] = useState("");
    const [confirmationMessage, setconfirmationMessage] = useState("");
    const [professinalUser, setProfessionalUser] = useState([]);
    const [showExport, setshowExport] = useState(false);
    const [uniqueId, setUniqueId] = useState("");
    const [action, setAction] = useState("");
    const [isLoading, setisLoading] = useState(false);
    const refElement = useRef(null);
    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = async () => {
        try {
            setisLoading(true);
            const response = (await professinalList()).data;
            if (response.success) {
                setProfessionalUser(response.data);
            } else {
                toast.error(response.message);
            }
            setisLoading(false);
        } catch (error) {
            setisLoading(false);
            console.error('Error fetching data:', error);
        }
    };

    const closeProfessionalCard = () => {
        setshowExport(false);
    }

    const handleApprove = (user) => {
        setDialogMessage(`Are you sure you want to approve ${user.name}?`);
        setconfirmationMessage('Approve confirmation')
        setAction('approve')
        setUniqueId(user.uniqueId);
        onOpen();
    };

    const handleReject = (user) => {
        setDialogMessage(`Are you sure you want to reject ${user.name}?`);
        setconfirmationMessage('Reject confirmation');
        setAction('reject')
        setUniqueId(user.uniqueId);
        onOpen();
    };
    const handleView = (user) => {
        setUniqueId(user.uniqueId);
        setAction('viewProfile');
        setshowExport(true);
    };

    const handleBottomScroll = () => {
        refElement.current.scrollIntoView({ behavior: 'smooth' });
    }
    const handleApproveAndReject = async (uniqueId, action) => {

        try {
            setisLoading(true);
            if (action === "viewProfile") {
                onClose();
            } else {
                const data = {
                    "uniqueId": uniqueId,
                    "status": action === "approve" ? "APPROVED" : "REJECTED"
                }
                const response = (await professionalApproveAndReject(data)).data;
                if (response.success) {
                    fetchData();
                    if (action === 'reject') {
                        setshowExport(false);
                    }
                    toast.success(response.message);
                    onClose();
                } else {
                    toast.error(response.message);
                    onClose();
                }
            }
            setisLoading(false);
        } catch (error) {
            setisLoading(false);
            console.error('Error fetching data:', error);
        }
    }

    return (
        <>
            <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
                {isLoading && <Loader />}
                <ToastContainer theme="colored" />
                <SimpleGrid columns={1} spacing={10}>
                    <Box height='80px'>
                        <Card>
                            <TableContainer>
                                <Table size='sm'>
                                    <Thead>
                                        <Tr>
                                            <Th fontSize={15} textAlign="center">Name</Th>
                                            <Th fontSize={15} textAlign="center">Email</Th>
                                            <Th fontSize={15} textAlign="center">Status</Th>
                                            <Th fontSize={15} textAlign="center">Action</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {professinalUser.map((user, index) => (
                                            <Tr key={index}>
                                                <Td textAlign="center">{user.name}</Td>
                                                <Td textAlign="center">{user.email}</Td>
                                                <Td textAlign="center">{user.status === 'NOT-COMPLETE' ? 'Pending' : user.status}</Td>
                                                <Td textAlign="center">
                                                    <Stack spacing={4} direction='row' justifyContent="center">
                                                        <Button colorScheme="yellow" size="sm" onClick={() => handleView(user)}>View</Button>
                                                        <Button colorScheme="green" size="sm" onClick={() => handleApprove(user)}>Approve</Button>
                                                        <Button colorScheme="red" size="sm" onClick={() => handleReject(user)}>Reject</Button>
                                                    </Stack>
                                                </Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </TableContainer>
                            { !professinalUser.length && !isLoading  && < Flex justify={'center'} pt={20} pb={20}>
                                <Text fontWeight={300} fontSize={18}>There is no professional List</Text>
                            </Flex>}
                        </Card>
                        <div ref={refElement} style={{ paddingTop: '23px', paddingBottom: '23px' }}>
                            {showExport && <Profile uniqueId={uniqueId} closeProfessionalCard={closeProfessionalCard} handleBottomScroll={handleBottomScroll} />}
                        </div>
                    </Box>
                </SimpleGrid>
            </Box>

            <AlertDialog
                motionPreset='slideInBottom'
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isOpen={isOpen}
                isCentered
            >
                <AlertDialogOverlay />

                <AlertDialogContent>
                    <AlertDialogHeader>{confirmationMessage}</AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                        {dialogMessage}
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button colorScheme="red" ref={cancelRef} onClick={onClose}>
                            No
                        </Button>
                        <Button colorScheme='green' ml={3} onClick={() => handleApproveAndReject(uniqueId, action)}>
                            Yes
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </>

    );
}
