import React from 'react'
import { Spinner,Flex } from '@chakra-ui/react'

const loader = () => {
  return (
    <Flex
    position="fixed"
    top="50%"
    left="50%"
    transform="translate(-50%, -50%)"
    zIndex="9999" 
    alignItems="center"
    justifyContent="center"
    width="100vw"
    height="100vh"
    background="rgba(255, 255, 255, 0.7)" 
  >
    <Spinner
      thickness="4px"
      speed="0.65s"
      emptyColor="gray.200"
      color="blue.500"
      size="xl"
    />
  </Flex>
  )
}

export default loader
