import axios from 'axios';

let baseURL = 'https://api.wewantmore.app/'
let access_token = localStorage.getItem('access_token');
const axiosClient = axios.create({
    baseURL:baseURL,
    headers: {
        'Content-Type': 'application/json',
        'Accept':'application/json',
        token:access_token?access_token:''
    },
});

// login API

export const loginAPI = async (data) => axiosClient.post('admin/signin', data);
export const professinalList = async () => axiosClient.get('admin/professionalList');
export const professionalApproveAndReject = async (data) => axiosClient.post('admin/updateStatus',data);
export const professionalDetails = async (data) => axiosClient.patch('admin/userAllData',data);

