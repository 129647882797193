import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import 'react-toastify/dist/ReactToastify.css';

const hasAccessToken = !!localStorage.getItem('access_token');

ReactDOM.render(
	<ChakraProvider theme={theme}>
  <React.StrictMode>
    <Router basename="/web-admin">
      <Switch>
        <Route path="/auth">
          {!hasAccessToken ? <AuthLayout /> : <Redirect to="/admin" />}
        </Route>
        <Route path="/admin">
          {hasAccessToken ? <AdminLayout /> : <Redirect to="/auth" />}
        </Route>
        <Redirect from="/" to="/admin" />
      </Switch>
    </Router>
  </React.StrictMode>
</ChakraProvider>,
	document.getElementById('root')
);
