import { Box } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import { TableContainer, Table, Thead, Tbody, Tr, Th, Td, Button, Stack } from '@chakra-ui/react';
import { Flex, Avatar, Text, Heading, useColorModeValue } from '@chakra-ui/react';
import Card from "components/card/Card";
import Loader from "components/loader/loader";
import { professionalDetails } from "service/axiosClient";
import { CloseIcon } from '@chakra-ui/icons'

export default function Overview({ uniqueId, closeProfessionalCard, handleBottomScroll }) {
  const [professionalExportDetails, setprofessionalExportDetails] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [isDataLoaded, setisDataLoaded] = useState(false);

  useEffect(() => {
    getProfessionalDetails();
  }, [uniqueId]);

  const getProfessionalDetails = async () => {
    try {
      setisLoading(true);
      let data = { uniqueId: uniqueId };
      const response = (await professionalDetails(data)).data;
      if (response.success) {
        setprofessionalExportDetails(response.data[0]);
        setisLoading(false);
        setisDataLoaded(true);
        handleBottomScroll();
      } else {
        toast.error(response.message);
        setisLoading(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setisLoading(false);
      toast.error(error.response.data.message);
      closeProfessionalCard();
    }
  };
  const closeProfessionalDetailCard = () => {
    closeProfessionalCard();
  }
  const bg = useColorModeValue('gray.100', 'gray.700');

  if (isDataLoaded) {
    return (
      <Box pt={{ base: "130px", md: "20px", xl: "20px" }} bg={bg} boxShadow={'2xl'} rounded={'lg'} textAlign={'center'}>
        {isLoading && <Loader />}
        <Stack paddingRight={5} direction="row" justifyContent={'end'} >
          <CloseIcon boxSize={5} cursor={"pointer"} onClick={closeProfessionalDetailCard} />
        </Stack>
        {professionalExportDetails && (
          <Card>
            <Flex align="center" justify="center">
              <Box width={'full'} >
                <Avatar size={'xl'} src={professionalExportDetails.imageUrl} alt={'User Avatar'} mb={4} pos={'relative'} />
                <Heading fontSize={'2xl'} fontFamily={'body'}>
                  {professionalExportDetails.firstName} {professionalExportDetails.lastName}
                </Heading>
                <Flex align="center" justify="center" >
                  <Stack textAlign={'start'} mt={6} boxSize="50%" >
                    <Text fontWeight={500}> <span style={{ paddingRight: '25px' }} >Email  :</span>  <span>{professionalExportDetails.email}</span></Text>
                    <Text fontWeight={500}> <span style={{ paddingRight: '25px' }}>Phone :</span> {professionalExportDetails.mobile}</Text>
                    <Text fontWeight={500}> <span style={{ paddingRight: '25px' }}>Gender :</span> {professionalExportDetails.gender}</Text>
                    <Text fontWeight={500} > <span style={{ paddingRight: '25px' }}>Language :</span>  <span>{professionalExportDetails.lang}</span></Text>
                    <Text fontWeight={500} > <span style={{ paddingRight: '25px' }}>Country :</span>  <span>{professionalExportDetails.country}</span></Text>
                    <Text fontWeight={500} > <span style={{ paddingRight: '25px' }}>experience :</span>  <span>{professionalExportDetails.experience}</span></Text>
                  </Stack>
                </Flex>
              </Box>
            </Flex>
          </Card>
        )}
      </Box>
    );
  } else {
    return (
      <> {isLoading && <Loader />}</>
    )
  }
}